import type { IconProps } from '../utils';

export function DefiLlama(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="31.34 197.14 116.4 131.45"
      width="1em"
      height="1em"
      {...props}
    >
      {props.title && <title>{props.title}</title>}
      <defs>
        <linearGradient
          id="dflm-a"
          x1="21.35"
          y1="140.45"
          x2="18.88"
          y2="120.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".29" stopColor="#3a8bff" />
          <stop offset="1" stopColor="#81b5ff" />
        </linearGradient>
        <linearGradient
          id="dflm-b"
          x1="53.25"
          y1="-13.54"
          x2="4.51"
          y2="112.02"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".29" stopColor="#0062ee" />
          <stop offset="1" stopColor="#00398a" />
        </linearGradient>
        <linearGradient
          id="dflm-c"
          x1="77.94"
          y1="130.62"
          x2="94.45"
          y2="36.26"
          xlinkHref="#dflm-b"
        />
        <linearGradient
          id="dflm-d"
          x1="58.26"
          y1="162.52"
          x2="58.26"
          y2="74.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".29" stopColor="#b3d2ff" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="dflm-e"
          x1="34.76"
          y1="27.73"
          x2="4.26"
          y2="8.77"
          xlinkHref="#dflm-a"
        />
        <path
          id="dflm-f"
          d="M48.409 265.908a3.57 3.57 0 0 1-3.24.48l-3.18-1.09a2.43 2.43 0 0 1 0-4.6l3.18-1.09a3.58 3.58 0 0 1 3.24 6.3"
        />
      </defs>
      <g fillRule="evenodd">
        <use xlinkHref="#dflm-f" fill="#0dff81" />
        <path
          d="M47.709 292.578a2 2 0 0 1 .56-2.62l7.41-5.34a2.93 2.93 0 0 1 4.09.66c.06.08.11.17.16.25a2.92 2.92 0 0 1-1.07 4l-.27.14-8.33 3.75a2 2 0 0 1-2.55-.83m12.22-51.1h0a3 3 0 0 1-1.92 1.39 2.92 2.92 0 0 1-2.33-.48l-7.41-5.35a2 2 0 0 1 2-3.44l8.33 3.74a2.92 2.92 0 0 1 1.48 3.86c0 .09-.09.18-.14.27"
          fill="#fff"
        />
      </g>
      <path
        d="M36.09,106.54l-.84,24.91H11.85A11.86,11.86,0,0,1,0,119.6H0V114.3a87.21,87.21,0,0,0,36.09-7.76"
        transform="translate(31.339 197.138)"
        fill="url(#dflm-a)"
      />
      <g fillRule="evenodd">
        <path
          d="M130.439 292.578a2 2 0 0 1-2.55.83l-8.33-3.75a2.91 2.91 0 0 1-1.48-3.84l.15-.29a2.91 2.91 0 0 1 4-1.07l.25.16 7.41 5.34a2 2 0 0 1 .56 2.62m-.57-55.52l-7.41 5.35a2.94 2.94 0 0 1-4.1-.69l-.14-.22h0a2.91 2.91 0 0 1 1-4 1.59 1.59 0 0 1 .29-.15l8.33-3.74a2 2 0 0 1 2 3.44"
          fill="#fff"
        />
        <path
          d="M138.809 262.998a2.42 2.42 0 0 1-1.64 2.3l-3.19 1.09a3.58 3.58 0 0 1-2.3-6.78 3.62 3.62 0 0 1 2.3 0l3.19 1.09a2.43 2.43 0 0 1 1.64 2.3"
          fill="#b3d2ff"
        />
      </g>
      <path
        d="M28.84,42a3,3,0,0,1-.25,2.36h0a3,3,0,0,1-1.92,1.39,2.92,2.92,0,0,1-2.33-.48l-7.41-5.35a2,2,0,0,1,2-3.44l8.33,3.74A2.93,2.93,0,0,1,28.84,42m0,48.76a2.93,2.93,0,0,1-1.59,1.77l-8.33,3.75a2,2,0,0,1-2-3.45l7.41-5.34a2.93,2.93,0,0,1,4.09.66c.06.08.11.17.16.25a3,3,0,0,1,.25,2.36m-15-21.5-3.18-1.09a2.43,2.43,0,0,1,0-4.6l3.18-1.09a3.58,3.58,0,1,1,2.3,6.78,3.62,3.62,0,0,1-2.3,0M50.72,0H32.34A32.36,32.36,0,0,1,0,32.35V114.3a87.21,87.21,0,0,0,36.09-7.76l1.43-42.36c-2.53-1.25-4.34-4.57-4.34-8.45,0-4.37,2.28-8,5.29-8.82a20,20,0,0,1,2.74-7.42A4.36,4.36,0,0,1,38,37.41c-2.72-4-6.39-8.1-9.2-14.73a3.52,3.52,0,0,1,.38-3.55,1.75,1.75,0,0,1,2.45-.33,2.09,2.09,0,0,1,.22.2c4.35,4.12,9.58,5.35,12.47,9.3a5.57,5.57,0,0,1,.83-1.36,6.06,6.06,0,0,1,7.16-1.88,6.19,6.19,0,0,1,11.87,0,5.7,5.7,0,0,1,4.16-.2A86.69,86.69,0,0,1,80.64,7.19,65.4,65.4,0,0,0,50.72,0"
        transform="translate(31.339 197.138)"
        fill="url(#dflm-b)"
      />
      <use xlinkHref="#dflm-f" fill="#b3d2ff" fillRule="evenodd" />
      <g transform="translate(31.339 197.138)">
        <path
          d="M105.83 68.16l-3.19 1.09a3.58 3.58 0 0 1-2.3-6.78 3.62 3.62 0 0 1 2.3 0l3.19 1.09a2.43 2.43 0 0 1 0 4.6M99.1 95.44a2 2 0 0 1-2.55.83l-8.33-3.75a2.91 2.91 0 0 1-1.48-3.84l.15-.29a2.91 2.91 0 0 1 4-1.07l.25.16 7.41 5.34a2 2 0 0 1 .56 2.62M86.63 42a2.93 2.93 0 0 1 1.59-1.77l8.33-3.74a2 2 0 0 1 2 3.44l-7.41 5.35a2.94 2.94 0 0 1-4.1-.69l-.14-.22h0a2.94 2.94 0 0 1-.27-2.37m-.33-31.54l-5.66-3.27a86.69 86.69 0 0 0-12.3 17.67 6.25 6.25 0 0 1 3 2.08 6.52 6.52 0 0 1 .83 1.36c2.71-3.71 7.48-4.55 11.66-8.53a2.45 2.45 0 0 1 3.17-.31 2 2 0 0 1 .55.54 2.41 2.41 0 0 1 .29 2.5l-.12.28c-2.8 6.5-6.45 10.64-9.15 14.63a4.39 4.39 0 0 1-3.26 2.08A19.74 19.74 0 0 1 78 46.91c2.71.74 4.82 3.74 5.22 7.52a11.33 11.33 0 0 1 .07 1.3c0 3.65-1.6 6.81-3.91 8.2a2.67 2.67 0 0 1-.43.25v.63l2 59.26a65.72 65.72 0 0 0 35.45-58.34h0a65.69 65.69 0 0 0-30.1-55.27"
          fill="url(#dflm-c)"
        />
        <path
          d="M70.73 62a3.93 3.93 0 0 1-.22 4.17c-2.85 4.16-6.84 7.32-11.92 7.32h-1.08c-4.39-.38-9-3.25-11.85-6.7a4 4 0 0 1-.37-4.53A12 12 0 0 0 47 56.09a12.43 12.43 0 0 0-1.46-5.85 3.92 3.92 0 0 1 .22-4.13 4.68 4.68 0 0 0 .75.79 5.84 5.84 0 0 0 7.09.1c0 2.27 2.29 4.11 5.1 4.11a5 5 0 0 0 .77 0c2.46-.3 4.34-2 4.34-4.06A5.84 5.84 0 0 0 71 46.9l.28-.25a4 4 0 0 1-.37 3.35 11.9 11.9 0 0 0-1.68 6.13 12.4 12.4 0 0 0 1.5 5.9M87.52 20a2 2 0 0 0-.55-.54 2.45 2.45 0 0 0-3.13.31c-4.18 4-9 4.82-11.66 8.53a6.52 6.52 0 0 0-.83-1.36 6.25 6.25 0 0 0-3-2.08 5.7 5.7 0 0 0-4.16.2 6.19 6.19 0 0 0-11.87 0 6.06 6.06 0 0 0-7.16 1.88 5.57 5.57 0 0 0-.83 1.36c-2.89-3.95-8.12-5.18-12.47-9.3a1.74 1.74 0 0 0-2.47-.13 1.48 1.48 0 0 0-.24.26 3.52 3.52 0 0 0-.38 3.55c2.81 6.63 6.48 10.71 9.2 14.73a4.36 4.36 0 0 0 3.25 2.08 20 20 0 0 0-2.74 7.42c-3 .82-5.29 4.45-5.29 8.82 0 3.88 1.81 7.2 4.34 8.45l-1.43 42.36-.84 24.91h15.46a65.4 65.4 0 0 0 29.92-7.19l.35-.19L79 64.81v-.63a2.67 2.67 0 0 0 .43-.25c2.31-1.39 3.91-4.55 3.91-8.2a11.33 11.33 0 0 0-.07-1.3c-.4-3.78-2.51-6.78-5.22-7.52a19.59 19.59 0 0 0-2.74-7.42 4.39 4.39 0 0 0 3.26-2.08c2.7-4 6.35-8.13 9.15-14.63l.12-.28a2.41 2.41 0 0 0-.29-2.5"
          fill="url(#dflm-d)"
        />
        <path
          d="M32.34,0A32.36,32.36,0,0,1,0,32.35V11.85A11.86,11.86,0,0,1,11.85,0Z"
          fill="url(#dflm-e)"
        />
      </g>
      <g fillRule="evenodd">
        <path
          d="M95.519 254.348l-3.33 3.06a2.09 2.09 0 0 0-.59 1l-1 4.25a1 1 0 0 1-1 .76h0a1 1 0 0 1-1-.76l-1.06-4.25a2 2 0 0 0-.59-1l-3.33-3.06a1 1 0 0 1 0-1.41 1 1 0 0 1 .85-.31l5 .68h.27l.39-.05 4.61-.63a1 1 0 0 1 1 .56 1 1 0 0 1-.23 1.16m5-1.12a11.93 11.93 0 0 1 1.74-6.09 4 4 0 0 0 .32-3.31l-.23.21a5.84 5.84 0 0 1-7.14.06c0 2.04-1.86 3.76-4.34 4.04a5 5 0 0 1-.77 0c-2.81 0-5.1-1.84-5.1-4.11a5.84 5.84 0 0 1-7.14-.06 4.68 4.68 0 0 1-.75-.79 3.92 3.92 0 0 0-.22 4.13 12.39 12.39 0 0 1 1.46 5.92 11.93 11.93 0 0 1-1.66 6.11 4 4 0 0 0 .37 4.53c2.87 3.45 7.46 6.32 11.85 6.7h1.08c5.08 0 9.07-3.16 11.92-7.32a3.93 3.93 0 0 0 .17-4.11 12.4 12.4 0 0 1-1.5-5.9"
          fill="#b3d2ff"
        />
        <path
          d="M95.519 254.348l-3.33 3.06a2.09 2.09 0 0 0-.59 1l-1 4.25a1 1 0 0 1-1 .76h0a1 1 0 0 1-1-.76l-1.06-4.25a2 2 0 0 0-.59-1l-3.33-3.06a1 1 0 0 1 0-1.41 1 1 0 0 1 .85-.31l5 .68h.27l.39-.05 4.61-.63a1 1 0 0 1 1 .56 1 1 0 0 1-.23 1.16"
          fill="#003580"
        />
      </g>
    </svg>
  );
}
