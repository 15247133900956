import type { IconProps } from '../utils';

export function LooksRare(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 12.32 96 76.54"
      width="1em"
      height="1em"
      {...props}
    >
      {props.title && <title>{props.title}</title>}
      <path d="M21.651 28.366c14.552-14.47 38.146-14.47 52.697 0l12.57 12.499-12.57 12.499c-14.552 14.47-38.146 14.47-52.698 0L9.081 40.865l12.57-12.499z" />
      <path
        fillRule="evenodd"
        d="M0 40.885l28.541-28.56H67.46L96 40.885l-48 47.98-48-47.98zm70.054-10.398c-12.127-12.18-31.982-12.18-44.108 0L15.568 40.865l10.378 10.378c12.127 12.18 31.981 12.18 44.108 0l10.378-10.378-10.378-10.379z"
        fill="#0ce466"
      />
      <path d="M48 49.946c-5.013 0-9.081-4.064-9.081-9.081s4.068-9.081 9.081-9.081 9.081 4.064 9.081 9.081-4.068 9.081-9.081 9.081z" />
      <path
        fillRule="evenodd"
        d="M33.081 40.865c0 8.243 6.683 14.919 14.919 14.919s14.919-6.676 14.919-14.919S56.236 25.946 48 25.946s-14.919 6.676-14.919 14.919zm8.432 0A6.49 6.49 0 0 0 48 47.352a6.49 6.49 0 0 0 6.486-6.486A6.49 6.49 0 0 0 48 34.379a6.49 6.49 0 0 0-6.486 6.486z"
        fill="#fff"
      />
    </svg>
  );
}

export function LooksRare2(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 12.32 96 76.54"
      width="1em"
      height="1em"
      fill="#04cd58"
      {...props}
    >
      {props.title && <title>{props.title}</title>}
      <path d="M48 55.784c-8.236 0-14.919-6.676-14.919-14.919S39.764 25.946 48 25.946s14.919 6.676 14.919 14.919S56.236 55.784 48 55.784zm-6.486-14.919A6.49 6.49 0 0 0 48 47.351a6.49 6.49 0 0 0 6.486-6.486A6.49 6.49 0 0 0 48 34.378a6.49 6.49 0 0 0-6.486 6.486zM0 40.885l28.541-28.56H67.46L96 40.885l-48 47.98-48-47.98zm70.054-10.398c-12.127-12.18-31.982-12.18-44.108 0L15.568 40.865l10.378 10.378c12.127 12.18 31.981 12.18 44.108 0l10.378-10.378-10.378-10.379z" />
    </svg>
  );
}

export function LooksRareMono(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 12.32 96 76.54"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      {props.title && <title>{props.title}</title>}
      <path d="M48 55.784c-8.236 0-14.919-6.676-14.919-14.919S39.764 25.946 48 25.946s14.919 6.676 14.919 14.919S56.236 55.784 48 55.784zm-6.486-14.919A6.49 6.49 0 0 0 48 47.351a6.49 6.49 0 0 0 6.486-6.486A6.49 6.49 0 0 0 48 34.378a6.49 6.49 0 0 0-6.486 6.486zM0 40.885l28.541-28.56H67.46L96 40.885l-48 47.98-48-47.98zm70.054-10.398c-12.127-12.18-31.982-12.18-44.108 0L15.568 40.865l10.378 10.378c12.127 12.18 31.981 12.18 44.108 0l10.378-10.378-10.378-10.379z" />
    </svg>
  );
}
